// Angular
import { OverlayModule } from '@angular/cdk/overlay';
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { MatRadioModule } from '@angular/material/radio';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// Timeout
import { NgIdleModule } from '@ng-idle/core';
import { EffectsModule } from '@ngrx/effects';
import { RouterState, StoreRouterConnectingModule } from '@ngrx/router-store';
// NGRX
import { CaiServicesModule } from '@cai-services';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

// NGX Permissions
import { NgxPermissionsModule } from 'ngx-permissions';
import {
  AuthModule,
  CaiCommonModule,
  CommonComponentsModule,
  CommonThemeModule,
  CoreModule,
  GlobalService,
  IntercomService,
  KtDialogService,
  LayoutConfig,
  LayoutConfigService,
  LayoutRefService,
  LayoutUtilsService,
  MenuAsideService,
  MenuConfigService,
  MenuHorizontalService,
  PageConfigService,
  RouterService,
  SplashScreenService,
  SubheaderService,
  reducers,
} from '@cai-common';
import { environment } from '@cai-common';
import { AppInitService } from './app-init.service';
// Modules
import { AppRoutingModule } from './app-routing.module';
// Copmponents
import { AppComponent } from './app.component';
// CRUD
// Auth
import { AppProperties } from './app.properties';
import { MatChipsModule } from '@angular/material/chips';

export function initializeLayoutConfig(
  appConfig: LayoutConfigService,
  appLoadService: AppInitService,
) {
  // initialize app by loading default demo layout config
  return () => {
    if (appConfig.getConfig() === null) {
      appConfig.loadConfigs(new LayoutConfig().configs);
    }
    return appLoadService.init();
  };
}

@NgModule({
  declarations: [AppComponent],
  exports: [],
  bootstrap: [AppComponent],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    NgxPermissionsModule.forRoot(),
    CoreModule,
    OverlayModule,
    StoreModule.forRoot(reducers, {
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    CaiCommonModule.forRoot(new AppProperties()),
    CaiServicesModule.forRoot(environment),
    EffectsModule.forRoot([]),
    StoreRouterConnectingModule.forRoot({
      stateKey: 'router',
      routerState: RouterState.Minimal,
    }),
    StoreDevtoolsModule.instrument(),
    AuthModule.forRoot(),
    MatProgressSpinnerModule,
    MatChipsModule,
    CommonThemeModule,
    MatRadioModule,
    NgIdleModule.forRoot(),
    MatAutocompleteModule,
    CommonComponentsModule,
  ],
  providers: [
    LayoutConfigService,
    LayoutRefService,
    MenuConfigService,
    PageConfigService,
    KtDialogService,
    GlobalService,
    SplashScreenService,
    RouterService,
    AppInitService,
    {
      // layout config initializer
      provide: APP_INITIALIZER,
      useFactory: initializeLayoutConfig,
      deps: [LayoutConfigService, AppInitService],
      multi: true,
    },
    // template services
    SubheaderService,
    MenuHorizontalService,
    MenuAsideService,
    LayoutUtilsService,
    IntercomService,
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
